@import "~antd/dist/antd.css";
@import "./utility.scss";

.login-layout {
  .logo {
    height: 6rem;
    margin-bottom: 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.ant-layout-sider-children .logo {
  height: 40px;
  margin: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}

.site-layout {
  min-height: calc(100vh - 64px);
  margin: 64px 0 0 0;

  &.open {
    margin: 64px 0 0 200px;
  }

  @media (max-width: 767px) {
    transition: all 0.2s;
    margin: 64px 0 0 0;
    &.open {
      transform: translateX(100%);
    }
  }
}

.site-layout-main {
  overflow-x: hidden;
}

.site-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  position: fixed;
  z-index: 1;
  width: 100%;
  // background: #fff;
}

.site-layout-sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  margin-top: 64px;
  // background: #fff;
}

.ant-page-header {
  @media (max-width: 767px) {
    padding: 8px;
  }
}

.site-layout-content {
  margin: 0 24px 24px;
  padding: 16px 24px;
  background: #fff;

  @media (max-width: 767px) {
    padding: 8px 16px;
    margin: 8px;
  }
}

.bg-white {
  background-color: #fff;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.vh-100 {
  height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-top: 0;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.position-absolute {
  position: absolute !important;
}

.position-absolute-full {
  @extend .position-absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overflow-auto {
  overflow: auto !important;
}

.chat__site-layout-content {
  @extend .site-layout-content;
  display: flex;
  flex-direction: column;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.ant-comment-direction-rtl {
  .ant-comment-avatar {
    order: 1;
    margin-left: 12px;
  }

  .ant-comment-content-author-name {
    order: 1;
  }

  .ant-comment-content-detail {
    text-align: right;
  }

  .ant-comment-content-author {
    justify-content: flex-end;
  }
}
